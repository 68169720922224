.file-view {
  height: 94vh;
  /* width: 95%; */
  margin: auto;
  padding-top: 10vh;
}
.file-storage-button {
  background-color: hsla(110, 96%, 28%, 0.05);
  margin: auto;
  padding: 1em;
  cursor: pointer;
}
.file-storage-button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(43, 255, 0);
}
.file-storage-button img {
  margin: auto;
  padding: 0;
}
