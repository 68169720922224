input[type="file"] {
  background-color: transparent;
  width: 50%;
  display: none;
}

.progress-bar {
  border-top: 1px dotted chartreuse;
}

.upload-percentage {
  color: chartreuse;
  min-width: fit-content;
  max-width: 95%;
}
