@media screen and (min-width: 600px) {
  .chat-list {
    margin: auto;
  }
}
.chat-list {
  height: 90vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 0.03em;
}
.chat-list::-webkit-scrollbar {
  display: none;
}

.message-empty {
  opacity: 0;
}
