@import "../node_modules/picnic/picnic.min.css";
@font-face {
  font-family: "White Rabbit";
  src: url("./Fonts/whiterabbit/WHITERABBIT.TTF");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "White Rabbit" !important;
  letter-spacing: 1px;
  background-color: #414141;
  font-size: 12px;
}
