.send-form {
  width: 97%;
  margin: auto;
  padding-top: 10px;
  border-top: 1px solid hsla(104, 100%, 50%, 0.5);
}
.send-button {
  background: hsla(104, 100%, 50%, 0.5);
}
.message-input {
  background-color: transparent;
  height: auto;
  border-top: none;
  border-right: none;
  border-left: none;
}
.message-input:focus,
.message-input:active {
  transition: border-color 0.2s ease-in-out;
  border-color: hsla(104, 100%, 50%, 0.5);
}
