.username-form {
  /* position: relative;
  top: 50%; */
  font-size: 1.2em;
}

.username-view {
  padding-top: 25%;
  height: 90vh;
}
