.App {
  text-align: center;
  width: 98%;
  margin: auto;
  height: 100vh;
  color: rgba(255, 255, 255, 0.75);
}

@media only screen and (min-width: 60em) {
  .App {
    width: 50%;
    border: 1px solid rgba(128, 255, 0, 0.125);
    border-radius: 5px;
  }
}
